import styles from './NewIndicators.module.scss'
import { ReactComponent as Icon } from 'icons/circlesvg.svg'
import { ReactComponent as IconCircle } from 'icons/indicator_arc-01.svg'
import { useWindowSize } from 'common/hooks/useWindowSize'

const NewIndicator = ({ numericValue, littleCircle }) => {
  let values
  if (numericValue > 29 && numericValue < 33) {
    values = 29
  } else if (numericValue > 63 && numericValue < 66) {
    values = 63
  } else {
    values = numericValue
  }

  const { isMobile } = useWindowSize()

  const minimumValue = 33
  const maximumValue = 66
  const redColor = '#FF3152'
  const yellowColor = '#FFA800'
  const greenColor = '#05CE91'
  const isRedColor = value => {
    return value < minimumValue
  }
  const isYellowColor = value => {
    return value >= minimumValue && value < maximumValue
  }
  const angle = (values * 360) / 100

  let color
  if (isRedColor(numericValue)) {
    color = redColor
  } else if (isYellowColor(numericValue)) {
    color = yellowColor
  } else {
    color = greenColor
  }

  let radius
  if (isMobile) {
    radius = 32.5
  } else {
    radius = 48.5
  }

  if (littleCircle) {
    radius = 31.5
  }
  const circumference = 2 * Math.PI * radius

  const strokeDasharray = (angle / 360) * circumference
  return (
    <div className={littleCircle ? styles.progress_chart_little : styles.progress_chart}>
      {isMobile || littleCircle ? (
        <IconCircle className={styles.spaces} />
      ) : (
        <Icon className={styles.spaces} />
      )}
      <svg
        className={
          littleCircle ? styles.progress_chart__circleLittle : styles.progress_chart__circle
        }
      >
        <circle
          className={styles.progress_chart__arc3}
          cx='50%'
          cy='50%'
          r={radius}
          strokeLinecap='round'
          strokeDasharray={[strokeDasharray, circumference - strokeDasharray]}
          strokeDashoffset={0}
          stroke={color}
        />
      </svg>
      <div
        className={
          littleCircle ? styles.progress_chart__percentageLittle : styles.progress_chart__percentage
        }
      >
        {numericValue}
      </div>
    </div>
  )
}
export default NewIndicator
