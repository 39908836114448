import { useForm, Controller } from 'react-hook-form'
import cx from 'classnames'
import { blue } from '@material-ui/core/colors'
import styles from './AddCollaborativeModalForm.module.scss'
import { Alert } from '@material-ui/lab'
import {
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  ListItem,
  Collapse,
  Button,
  ListItemText,
  InputAdornment
} from '@material-ui/core'
import { LoadingButton } from '@mui/lab'

import PropTypes from 'prop-types'
import _ from 'lodash'
import { withLocalize, Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/styles'
import Select from 'common/components/Select'
import { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: blue[600],
      '&:hover': {
        // backgroundColor: blue[50],
        // backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity)
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-2px)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const AddCollaborativeModalForm = props => {
  const classes = useStyles()
  const {
    translate,
    systems,
    systemsGroup,
    onSubmit,
    edit,
    email,
    collaborators,
    isSubmitting,
    submitError
  } = props
  const [systemsGroupState, setSystemsGroupState] = useState(systemsGroup)
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [open, setOpen] = useState({})
  const [openInfoE, setOpenInfoE] = useState(false)
  const [openInfoR, setOpenInfoR] = useState(false)
  const [groupSelected, setGroupSelected] = useState([])
  const [dataState, setDataState] = useState({})
  const [editDataState, setEditDataState] = useState({})

  const deviceIds = edit
    ? collaborators.filter(s => s.email.toLowerCase() === email.toLowerCase())[0]['deviceIds']
    : []

  const roleOptions = [
    { label: translate('Manager'), value: 'Manager' },
    { label: translate('Viewer'), value: 'Viewer' }
  ]
  const roleValue = edit
    ? collaborators.filter(s => s.email.toLowerCase() === email.toLowerCase())[0]['role']
    : null
  let initialRole
  if (edit && roleValue) {
    initialRole = roleOptions.find(e => e.value === roleValue)
  }
  const [value, setValue] = useState(initialRole?.value)

  useEffect(() => {
    if (edit) {
      setSelectedDevices(deviceIds)
    }
  }, [])

  let keys = Object.keys(systemsGroupState)
  keys = keys.filter(function (element) {
    return element !== 'undefined'
  })
  const { register, errors, setError, clearErrors, control, getValues, handleSubmit } = useForm()

  const handleEmailChange = event => {
    if (event.target.value !== '') {
      clearErrors('wrongEmail')
      clearErrors('addressEmail')
    } else {
      setError('addressEmail', { shouldFocus: 'addressEmail' })
    }
  }

  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setGroupSelected([])
      setSelectedDevices([])
    } else {
      setGroupSelected(keys)
      const systemIds = []
      systemOrdered.map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleInfoE = () => {
    setOpenInfoE(!openInfoE)
    setOpenInfoR(false)
  }

  const handleInfoR = () => {
    setOpenInfoR(!openInfoR)
    setOpenInfoE(false)
  }

  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }

  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }

  const systemOrdered = []

  _.map(systemsGroupState, function (systems, group) {
    const systemsType = _.groupBy(systems, 'micaType')

    group === 'undefined' &&
      _.map(systemsType, function (systems, type) {
        systems.map((system, index) => {
          systemOrdered.push(system)
        })
      })
    group !== 'undefined' &&
      _.map(systemsType, function (systems, type) {
        systems.map((system, index) => {
          systemOrdered.push(system)
        })
      })
  })

  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }
  const handleSelectAllGroupDevices = (event, systems) => {
    const { checked, value } = event.target
    if (checked) {
      setGroupSelected([...groupSelected, value])
    } else {
      setGroupSelected(groupSelected.filter(item => item !== value))
    }
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) {
          aux.push(systems[i]._id)
        }
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }
  const onClickCancel = () => {
    props.hideModal()
  }

  const onClickAdd = () => {
    let selected = getValues()
    const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (selected?.selectedDevices !== undefined && !Array.isArray(selected.selectedDevices)) {
      selected.selectedDevices = [selected.selectedDevices]
    }
    if (
      value &&
      selected &&
      selected.selectedDevices !== undefined &&
      selected.selectedDevices.length > 0 &&
      getValues('addressEmail') !== '' &&
      emailFormat.test(getValues('addressEmail'))
    ) {
      const data = {}
      data.deviceIds = selected.selectedDevices
      data.email = getValues('addressEmail')
      data.role = value
      setDataState(data)
    } else {
      if (!value) {
        setError('role', { shouldFocus: 'role' })
      }

      if (selected.selectedDevices === undefined || selected.selectedDevices.length === 0) {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }

      if (getValues('addressEmail') === '') {
        setError('addressEmail', { shouldFocus: 'addressEmail' })
      }
      if (!emailFormat.test(getValues('addressEmail'))) {
        setError('wrongEmail', { shouldFocus: 'wrongEmail' })
      }
    }
  }
  const onClickEdit = () => {
    const selected = getValues()

    if (
      value &&
      selected &&
      selected.selectedDevices !== undefined &&
      selected.selectedDevices.length > 0 &&
      getValues('addressEmail') !== ''
    ) {
      const data = {}
      data.deviceIds = selected.selectedDevices
      data.email = email
      data.role = value
      data.confirmed = collaborators.filter(s => s.email.toLowerCase() === email.toLowerCase())[0][
        'confirmed'
      ]
      setEditDataState(data)
    } else {
      if (!value) {
        setError('role', { shouldFocus: 'role' })
      }
      if (selected.selectedDevices === undefined || selected.selectedDevices.length === 0) {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    }
  }

  const error = () => {
    if (!submitError) return null

    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 411) {
      message = translate('submitErrors.collaborativeUserNotValid')
    }
    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
      >
        {message}
      </Alert>
    )
  }

  return (
    <div className={styles.modalContainer}>
      <Typography className={styles.header}>
        {edit ? translate('editUser') : translate('addUser')}
      </Typography>

      <div className={styles.modal}>
        <form
          noValidate
          onSubmit={handleSubmit(() => (edit ? onSubmit(editDataState) : onSubmit(dataState)))}
        >
          <div className={styles.wrapperInfo}>
            <TextField
              variant='outlined'
              required
              fullWidth
              onChange={handleEmailChange}
              label={translate('newEmail')}
              id='addressEmail'
              name='addressEmail'
              error={errors.addressEmail}
              placeholder={translate('emailAddress')}
              defaultValue={edit ? email : dataState.email ? dataState.email : ''}
              disabled={edit}
              inputRef={register({ required: true })}
              className={cx(styles.input, classes.input, {
                [styles.hasError]: errors.addressEmail
              })}
            />
            {!edit && (
              <div className={styles.wrapperInfoButton}>
                <IconButton
                  className={styles.infoButton}
                  variant='outlined'
                  onClick={() => handleInfoE()}
                  value={' '}
                  name='infoParam'
                  disableRipple
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className={styles.wrapperDataInfo}>
            <div
              style={{
                display: openInfoE ? 'block' : 'none'
              }}
              className={styles.cajaEmail}
            >
              <Translate
                id={'newUserInfoN'}
                options={{ renderInnerHtml: true }}
              />
              <Button
                fullWidth
                disableRipple
                variant='contained'
                className={styles.buttonCancelar}
                onClick={e => {
                  setOpenInfoE(false)
                }}
              >
                {translate('close')}
              </Button>
            </div>
            <div
              id='overlayE'
              className={styles.overlay}
              style={{
                display: openInfoE ? 'block' : 'none'
              }}
            />
          </div>
          <div className={styles.wrapperError}>
            {errors.addressEmail && (
              <div className={styles.error}>
                <small>{translate('validation.required')}</small>
              </div>
            )}
            {errors.wrongEmail && (
              <div className={styles.error}>
                <small>{translate('validation.inValidEmail')}</small>
              </div>
            )}
          </div>
          <div className={styles.cselect}>
            <div className={styles.wrapperInfo}>
              <Controller
                id='role'
                name='role'
                className={styles.wrapperSelect}
                render={({ onChange }) => (
                  <div className={styles.custom}>
                    <Select
                      required
                      handleChange={e => {
                        setValue(e.value)
                        clearErrors('role')
                      }}
                      fullWidth
                      value={value}
                      defaultValue={initialRole}
                      autoComplete='role viewer'
                      options={roleOptions}
                      label={translate('role')}
                      placeholder={translate('role')}
                      error={errors.role}
                      inputRef={register({ required: true })}
                      className={cx(classes.selector, styles.selector, {
                        [styles.hasError]: errors.role
                      })}
                    />
                  </div>
                )}
                control={control}
                defaultValue={initialRole?.value}
              />
              <div className={styles.wrapperInfoButton}>
                <IconButton
                  className={styles.infoButton}
                  variant='outlined'
                  onClick={() => handleInfoR()}
                  value={' '}
                  name='infoParam'
                  disableRipple
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </div>
            </div>
            <div className={styles.wrapperDataInfo}>
              <div
                style={{
                  display: openInfoR ? 'block' : 'none'
                }}
                className={styles.cajaEmail}
              >
                <Translate
                  id={'rolesInfoN'}
                  options={{ renderInnerHtml: true }}
                />
                <div className={styles.roleButtonWrapper}>
                  <div className={styles.moreInfoButtonWrapper}>
                    <a
                      className={styles.moreInfoButton}
                      href='https://www.inbiot.es/other/collaborative-accounts '
                      target='_blank'
                      rel='noreferrer'
                    >
                      {'Más información acerca de los roles'.toUpperCase()}
                    </a>
                  </div>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={e => {
                      setOpenInfoR(false)
                    }}
                  >
                    {translate('close')}
                  </Button>
                </div>
              </div>

              <div
                id='overlayR'
                className={styles.overlay}
                style={{
                  display: openInfoR ? 'block' : 'none'
                }}
              />
            </div>
            {errors.role && (
              <div className={styles.error}>
                <small>{translate('validation.required')}</small>
              </div>
            )}
          </div>
          <Typography className={styles.subInfo}>{translate('deviceAccess')}</Typography>

          <div>
            <TextField
              variant='outlined'
              fullWidth
              type='search'
              onChange={filterExpressionChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
              id='searchDevice'
              name='searchDevice'
              placeholder={translate('searchDevice')}
              className={cx(styles.inputSearch, classes.input, {
                [styles.hasError]: errors.reportName
              })}
            />
            <FormControlLabel
              className={styles.selectAllCheckbox}
              control={
                <Checkbox
                  className={cx(styles.checkbox)}
                  classes={{ checked: styles.checkedBox }}
                  name='selectAllDevices'
                  id='selectAllDevices'
                  indeterminate={isIndeterminateDevices()}
                  onChange={handleSelectAllDevices}
                  checked={isCheckAllDevices}
                />
              }
              label={translate('selectAll')}
            />
            <Paper
              className={styles.devicesListPaper}
              classes={{ root: classes.paper }}
            >
              <List
                disableTouchRipple
                className={styles.devicesList}
              >
                {_.map(systemsGroupState, function (systems, group) {
                  const systemsType = _.groupBy(systems, 'micaType')
                  return (
                    <div className={styles.noGroupWrapper}>
                      {group !== 'undefined' && (
                        <div>
                          <ListItem
                            button
                            disableTouchRipple
                            className={styles.groupListItem}
                          >
                            {!open[group] ? (
                              <ExpandLess
                                onClick={() => handleClick(group)}
                                className={styles.expandIcon}
                              />
                            ) : (
                              <ExpandMore
                                onClick={() => handleClick(group)}
                                className={styles.expandIcon}
                              />
                            )}
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              name={group}
                              onChange={e => handleSelectAllGroupDevices(e, systems)}
                              id={group}
                              value={group}
                              checked={groupSelected.includes(group)}
                            />
                            <ListItemText
                              primary={group}
                              className={styles.listItemText}
                            />
                          </ListItem>
                        </div>
                      )}
                      {group === 'undefined' && (
                        <div>
                          {_.map(systemsType, function (systems, type) {
                            return (
                              <div className={styles.typeRowWrapper}>
                                <ListItem
                                  button
                                  disableTouchRipple
                                  className={styles.typeListItem}
                                >
                                  {!open[type] ? (
                                    <ExpandLess
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  )}
                                  <ListItemText
                                    primary={type}
                                    className={styles.micaTypeItemText}
                                  />
                                </ListItem>
                                <Collapse
                                  in={!open[type]}
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  {systems.map((system, index) => {
                                    return (
                                      <div
                                        key={system._id}
                                        className={styles.micaItem}
                                      >
                                        <FormControlLabel
                                          className={styles.checkboxForm}
                                          control={
                                            <Checkbox
                                              className={cx(styles.checkbox)}
                                              classes={{
                                                checked: styles.checkedBox,
                                                disabled: styles.disabledBox
                                              }}
                                              checked={selectedDevices.includes(system._id)}
                                              onChange={handleChangeDevice}
                                              required
                                              outline='true'
                                              inputRef={register()}
                                              disableRipple
                                              name={`selectedDevices[]`}
                                              value={system._id}
                                            />
                                          }
                                          label={system.name}
                                        />
                                      </div>
                                    )
                                  })}
                                </Collapse>
                              </div>
                            )
                          })}
                        </div>
                      )}

                      {group !== 'undefined' && (
                        <Collapse
                          in={!open[group]}
                          timeout='auto'
                          unmountOnExit
                          classes={{
                            wrapper: classes.wrapper
                          }}
                        >
                          {_.map(systemsType, function (systems, type) {
                            return (
                              <div className={styles.typeRowWrapper}>
                                <ListItem
                                  button
                                  disableTouchRipple
                                  className={styles.typeListItem}
                                >
                                  {!open[type] ? (
                                    <ExpandLess
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  )}
                                  <ListItemText
                                    primary={translate(type)}
                                    className={styles.micaTypeItemText}
                                  />
                                </ListItem>
                                <Collapse
                                  in={!open[type]}
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  {systems.map((system, index) => {
                                    return (
                                      <div
                                        key={index + 'systemFormControlLabelCheckbox'}
                                        className={styles.micaItem}
                                      >
                                        <FormControlLabel
                                          className={styles.checkboxForm}
                                          control={
                                            <Checkbox
                                              className={cx(styles.checkbox)}
                                              classes={{
                                                checked: styles.checkedBox,
                                                disabled: styles.disabledBox
                                              }}
                                              checked={selectedDevices.includes(system._id)}
                                              onChange={handleChangeDevice}
                                              required
                                              outline='true'
                                              inputRef={register()}
                                              disableRipple
                                              name={`selectedDevices[]`}
                                              value={system._id}
                                            />
                                          }
                                          label={system.name}
                                        />
                                      </div>
                                    )
                                  })}
                                </Collapse>
                              </div>
                            )
                          })}
                        </Collapse>
                      )}
                    </div>
                  )
                })}
              </List>
            </Paper>
            {errors.selectedDevices && (
              <div className={styles.error}>
                <small>{translate('validation.anyDeviceRequired')}</small>
              </div>
            )}
            {errors.selectedDevicesTime && (
              <div className={styles.error}>
                <small>{translate('validation.timeOutDevices')}</small>
              </div>
            )}
          </div>
          {error()}
          <div className={styles.downButtonsWrapper}>
            <div className={styles.buttonWrapper}>
              <LoadingButton
                fullWidth
                disableRipple
                disabled={isSubmitting}
                loading={isSubmitting}
                loadingPosition='end'
                type='submit'
                variant='contained'
                className={styles.buttonSiguiente1}
                onClick={edit ? onClickEdit : onClickAdd}
              >
                {translate('addUsers')}
              </LoadingButton>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disableRipple
                type='submit'
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onClickCancel}
              >
                {translate('addReportModal.cancel')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AddCollaborativeModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired
}

export default withLocalize(AddCollaborativeModalForm)
